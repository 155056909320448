import 'reset-css'
import ogImage from './pictures/meta.png'
// import { Analytics } from '@vercel/analytics'
import { inject } from '@vercel/analytics'

inject()

const defaultText = 'i am music'
const download = document.querySelector('.download')

import html2canvas from 'html2canvas'

const input = document.querySelector('input')
const result = document.querySelector('.result')

// Add this to your index.js file
window.addEventListener('load', function () {
	document.getElementById('loader').style.display = 'none'
})

const renderWords = (value) => {
	const words = value.trim().split(/(\s+)/)
	words
		.map((word) => {
			const isEmpty = !word.trim().length

			if (isEmpty) {
				return createEmptyLetter()
			} else {
				const div = document.createElement('div')
				div.classList.add('inline-block', 'leading-10')
				word
					.split('')
					.map(createLetter)
					.map((el) => div.appendChild(el))
				return div
			}
		})
		.flat()
		.map(addLetterToPage)
}

input.addEventListener('input', (e) => {
	input.value = input.value.replace(/[^a-zA-Z05\s]/g, '')
	if (input.value.length >= 35) {
		input.value = input.value.substring(0, 34)
		return
	}
	if (!input.value) {
		input.value = ''
		result.innerHTML = ''
		renderWords(defaultText)
		return
	}
	result.innerHTML = ''
	const value = input.value.trim() ?? defaultText
	renderWords(value)
})

const addLetterToPage = (letterDiv) => {
	result.appendChild(letterDiv)
}

const createEmptyLetter = () => {
	const letterDiv = document.createElement('div')
	letterDiv.classList.add('empty')
	return letterDiv
}

const createLetter = (item) => {
	const letter = item.toUpperCase()
	const letterDiv = document.createElement('p')

	letterDiv.classList.add('letter', 'inline-block')
	letterDiv.innerHTML = `<div class="alpha">${letter}</div><div class="second-letter">${letter}</div>`

	return letterDiv
}

function downloadImage() {
	html2canvas(download, { scale: 4 }).then((canvas) => {
		let link = document.createElement('a')
		link.download = 'result.png'
		link.href = canvas.toDataURL()
		link.click()
	})
}

const button = document.querySelector('button')
button.addEventListener('click', (e) => {
	e.preventDefault()
	result.classList.add('w-fit')
	downloadImage()
})

renderWords(defaultText)
